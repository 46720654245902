import React from 'react';
import { Link } from 'gatsby';

const getHeadings = (item, currentDepth, maxDepth, pathName) => {
  if (currentDepth > maxDepth) {
    return null;
  }

  let children = null;
  if (item.items) {
    children = item.items.map(i =>
      getHeadings(i, currentDepth + 1, maxDepth, pathName)
    );
  }

  return (
    <ul key={item.url}>
      {currentDepth > 1 && (
        <li className='ml-3 mb-2 text-sm text-blue-600'>
          <Link to={`${pathName}/${item.url}`}>{item.title}</Link>
        </li>
      )}
      {children}
    </ul>
  );
};

export default ({ items, maxDepth, pathName }) => {
  // Note that this function will throw an exception if there are no
  //  headings on the page. This is ok as all the pages we are writing
  //  will have at least one heading.
  return (
    <aside className='flex-shrink-0 w-64 p-2 relative'>
      <div className='fixed h-nav overflow-y-auto'>
        <h2 className='mt-0 mb-3 leading-none text-sm uppercase mt-12 text-gray-700'>
          On this page
        </h2>
        {items.map(item => getHeadings(item, 1, maxDepth + 1, pathName))}
      </div>
    </aside>
  );
};
