import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './shared/layout';
import Nav, { NavItem } from '../components/nav';
import PageIndex from '../components/pageIndex';
import FourOhFour from '../pages/404';

export default props => {
  if (!props.data.mdx) {
    // We couldn't find the requested page.
    return <FourOhFour />;
  }

  const pageTitle = `${props.data.site.siteMetadata.title} - ${props.data.mdx.frontmatter.title}`;
  const { tableOfContents, fields } = props.data.mdx;
  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name='description'
          content={props.data.site.siteMetadata.description}
        />
      </Helmet>
      <div className='flex min-h-full mt-32 lg:mt-16'>
        <Nav>
          {props.data.allMdx.edges.map(edge => {
            const { fields, frontmatter } = edge.node;
            return (
              <NavItem
                key={fields.pathName}
                to={fields.pathName}
                indent={frontmatter.sidenav_indent}
              >
                {frontmatter.title}
              </NavItem>
            );
          })}
        </Nav>
        <div className='flex-grow pt-4 max-w-4xl markdown py-4 pl-16 content'>
          <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
        </div>
        <div className='hidden lg:block pr-4'>
          <PageIndex
            maxDepth={2}
            items={tableOfContents.items}
            pathName={fields.pathName}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!, $isApi: Boolean!) {
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { draft: { ne: true } }) {
      body
      tableOfContents(maxDepth: 2)
      fields {
        slug
        pathName
      }
      frontmatter {
        title
      }
    }

    allMdx(
      filter: {
        fields: { isApi: { eq: $isApi } }
        frontmatter: { draft: { ne: true }, sidenav_hidden: { ne: false } }
      }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            pathName
          }
          frontmatter {
            title
            sidenav_indent
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
